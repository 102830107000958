import React from 'react'

interface WavyRightArrowProps extends React.SVGProps<SVGSVGElement> { }

const WavyRightArrow = (props: WavyRightArrowProps) => {
    return (
        <svg
            viewBox="0 0 190 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="
                M 0 15
                C 15 5,   30 25,  45 15
                C 60 5,   75 25,  90 15
                C 105 5, 120 25, 135 15
                M 125 5
                L 135 15
                M 125 25
                L 135 15
                "
                stroke="currentColor"
                strokeWidth={3}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default WavyRightArrow;
