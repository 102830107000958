import React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router';
import PhotoViewer from './PhotoViewer';
import { getPortfolioPhotoById, getPortfolioPhotos } from '../../helpers/getPhotoData';

const PortfolioPhotoViewer = () => {
    const { portfolioId, photoId } = useParams()
    const navigate = useNavigate()

    const photo = getPortfolioPhotoById(portfolioId ?? '', photoId ?? '')

    if (!photo) {
        return <Navigate to={'/404'} />
    }

    const setPrevPhoto = () => {
        const portfolioPhotos = getPortfolioPhotos(portfolioId ?? '')
        const currentIndex = portfolioPhotos.findIndex(
            (photo) => photo.id === photoId,
        )
        if (currentIndex === 0) {
            navigate(`/portfolio/${portfolioId}/${portfolioPhotos[portfolioPhotos.length - 1].id}`)
        } else {
            navigate(`/portfolio/${portfolioId}/${portfolioPhotos[currentIndex - 1].id}`)
        }
    }

    const setNextPhoto = () => {
        const portfolioPhotos = getPortfolioPhotos(portfolioId ?? '')
        const currentIndex = portfolioPhotos.findIndex(
            (photo) => photo.id === photoId,
        )
        if (currentIndex === portfolioPhotos.length - 1) {
            navigate(`/portfolio/${portfolioId}/${portfolioPhotos[0].id}`)
        } else {
            navigate(`/portfolio/${portfolioId}/${portfolioPhotos[currentIndex + 1].id}`)
        }
    }

    const setCurrentPhoto = () => {
        return navigate(`/portfolio/${portfolioId}`)
    }

    return (
        <PhotoViewer
            photo={photo}
            setPrevPhoto={setPrevPhoto}
            setNextPhoto={setNextPhoto}
            setCurrentPhoto={setCurrentPhoto}
        />
    );
}

export default PortfolioPhotoViewer