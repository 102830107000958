import React from 'react';
import PortfolioGallery from '../../components/PortfolioGallery';
import { Page } from '../../components/Page';

const PortfolioPage = () => {
    return (
        <Page>
            <PortfolioGallery />
        </Page>
    );
};

export default PortfolioPage;