type Links = Record<string, string | Record<string, string>>

export const linkData: Links = {
    Home: '',
    Portfolio: {
        Events: 'portfolio/events',
        Sports: 'portfolio/sports',
        Motorsports: 'portfolio/motorsports',
        Urban: 'portfolio/urban',
        Landscape: 'portfolio/landscape',
        Product: 'portfolio/product',
        People: 'portfolio/portrait',
    },
    About: 'about',
    Contact: 'contact',
    Travel: 'travel',
}
