import { Photo } from '../models/Photo'
import urban1 from '../media/urban/urban-01.jpg'
import urban2 from '../media/urban/urban-02.jpg'
import urban3 from '../media/urban/urban-03.jpg'
import urban4 from '../media/urban/urban-04.jpg'
import urban5 from '../media/urban/urban-05.jpg'
import urban6 from '../media/urban/urban-06.jpg'
import urban7 from '../media/urban/urban-07.jpg'
import urban8 from '../media/urban/urban-08.jpg'
import urban9 from '../media/urban/urban-09.jpg'
import urban10 from '../media/urban/urban-10.jpg'
import urban11 from '../media/urban/urban-11.jpg'
import urban12 from '../media/urban/urban-12.jpg'
import urban13 from '../media/urban/urban-13.jpg'
import urban14 from '../media/urban/urban-14.jpg'
import urban15 from '../media/urban/urban-15.jpg'
import urban16 from '../media/urban/urban-16.jpg'
import urban17 from '../media/urban/urban-17.jpg'
import urban18 from '../media/urban/urban-18.jpg'
import urban19 from '../media/urban/urban-19.jpg'
import urban20 from '../media/urban/urban-20.jpg'
import urban21 from '../media/urban/urban-21.jpg'
import urban22 from '../media/urban/urban-22.jpg'

import landscape1 from '../media/landscape/landscape-01.jpg'
import landscape2 from '../media/landscape/landscape-02.jpg'
import landscape3 from '../media/landscape/landscape-03.jpg'
import landscape4 from '../media/landscape/landscape-04.jpg'
import landscape5 from '../media/landscape/landscape-05.jpg'
import landscape6 from '../media/landscape/landscape-06.jpg'
import landscape7 from '../media/landscape/landscape-07.jpg'
import landscape8 from '../media/landscape/landscape-08.jpg'
import landscape9 from '../media/landscape/landscape-09.jpg'
import landscape10 from '../media/landscape/landscape-10.jpg'

import product1 from '../media/product/product-01.jpg'
import product2 from '../media/product/product-02.jpg'
import product3 from '../media/product/product-03.jpg'
import product4 from '../media/product/product-04.jpg'
import product5 from '../media/product/product-05.jpg'
import product6 from '../media/product/product-06.jpg'
import product7 from '../media/product/product-07.jpg'
import product8 from '../media/product/product-08.jpg'
import product9 from '../media/product/product-09.jpg'
import product10 from '../media/product/product-10.jpg'

import people1 from '../media/people/people-01.jpg'
import people2 from '../media/people/people-02.jpg'
import people3 from '../media/people/people-03.jpg'
import people4 from '../media/people/people-04.jpg'
import people5 from '../media/people/people-05.jpg'
import people6 from '../media/people/people-06.jpg'
import people7 from '../media/people/people-07.jpg'
import people8 from '../media/people/people-08.jpg'
import people9 from '../media/people/people-09.jpg'
import people10 from '../media/people/people-10.jpg'
import people11 from '../media/people/people-11.jpg'
import people12 from '../media/people/people-12.jpg'

import sports1 from '../media/sports/sports-01.jpg'
import sports2 from '../media/sports/sports-02.jpg'
import sports3 from '../media/sports/sports-03.jpg'
import sports4 from '../media/sports/sports-04.jpg'
import sports5 from '../media/sports/sports-05.jpg'
import sports6 from '../media/sports/sports-06.jpg'
import sports7 from '../media/sports/sports-07.jpg'
import sports8 from '../media/sports/sports-08.jpg'
import sports9 from '../media/sports/sports-09.jpg'
import sports10 from '../media/sports/sports-10.jpg'
import sports11 from '../media/sports/sports-11.jpg'
import sports12 from '../media/sports/sports-12.jpg'
import sports13 from '../media/sports/sports-13.jpg'
import sports14 from '../media/sports/sports-14.jpg'
import sports15 from '../media/sports/sports-15.jpg'
import sports16 from '../media/sports/sports-16.jpg'
import sports17 from '../media/sports/sports-17.jpg'
import sports18 from '../media/sports/sports-18.jpg'
import sports19 from '../media/sports/sports-19.jpg'
import sports20 from '../media/sports/sports-20.jpg'
import sports21 from '../media/sports/sports-21.jpg'
import sports22 from '../media/sports/sports-22.jpg'
import sports23 from '../media/sports/sports-23.jpg'
import sports24 from '../media/sports/sports-24.jpg'
import sports25 from '../media/sports/sports-25.jpg'
import sports26 from '../media/sports/sports-26.jpg'
import sports27 from '../media/sports/sports-27.jpg'
import sports28 from '../media/sports/sports-28.jpg'
import sports29 from '../media/sports/sports-29.jpg'
import sports30 from '../media/sports/sports-30.jpg'
import sports31 from '../media/sports/sports-31.jpg'
import sports32 from '../media/sports/sports-32.jpg'
import sports33 from '../media/sports/sports-33.jpg'
import sports34 from '../media/sports/sports-34.jpg'
import sports35 from '../media/sports/sports-35.jpg'
import sports36 from '../media/sports/sports-36.jpg'
import sports37 from '../media/sports/sports-37.jpg'
import sports38 from '../media/sports/sports-38.jpg'
import sports39 from '../media/sports/sports-39.jpg'
import sports40 from '../media/sports/sports-40.jpg'
import sports41 from '../media/sports/sports-41.jpg'
import sports42 from '../media/sports/sports-42.jpg'
import sports43 from '../media/sports/sports-43.jpg'

import motorsports1 from '../media/motorsports/motorsports-01.jpg'
import motorsports2 from '../media/motorsports/motorsports-02.jpg'
import motorsports3 from '../media/motorsports/motorsports-03.jpg'
import motorsports4 from '../media/motorsports/motorsports-04.jpg'
import motorsports5 from '../media/motorsports/motorsports-05.jpg'
import motorsports6 from '../media/motorsports/motorsports-06.jpg'
import motorsports7 from '../media/motorsports/motorsports-07.jpg'
import motorsports8 from '../media/motorsports/motorsports-08.jpg'
import motorsports9 from '../media/motorsports/motorsports-09.jpg'
import motorsports10 from '../media/motorsports/motorsports-10.jpg'
import motorsports11 from '../media/motorsports/motorsports-11.jpg'
import motorsports12 from '../media/motorsports/motorsports-12.jpg'
import motorsports13 from '../media/motorsports/motorsports-13.jpg'
import motorsports14 from '../media/motorsports/motorsports-14.jpg'
import motorsports15 from '../media/motorsports/motorsports-15.jpg'
import motorsports16 from '../media/motorsports/motorsports-16.jpg'
import motorsports17 from '../media/motorsports/motorsports-17.jpg'
import motorsports18 from '../media/motorsports/motorsports-18.jpg'
import motorsports19 from '../media/motorsports/motorsports-19.jpg'
import motorsports20 from '../media/motorsports/motorsports-20.jpg'
import motorsports21 from '../media/motorsports/motorsports-21.jpg'
import motorsports22 from '../media/motorsports/motorsports-22.jpg'
import motorsports23 from '../media/motorsports/motorsports-23.jpg'
import motorsports24 from '../media/motorsports/motorsports-24.jpg'
import motorsports25 from '../media/motorsports/motorsports-25.jpg'
import motorsports26 from '../media/motorsports/motorsports-26.jpg'
import motorsports27 from '../media/motorsports/motorsports-27.jpg'
import motorsports28 from '../media/motorsports/motorsports-28.jpg'
import motorsports29 from '../media/motorsports/motorsports-29.jpg'
import motorsports30 from '../media/motorsports/motorsports-30.jpg'
import motorsports31 from '../media/motorsports/motorsports-31.jpg'
import motorsports32 from '../media/motorsports/motorsports-32.jpg'
import motorsports33 from '../media/motorsports/motorsports-33.jpg'
import motorsports34 from '../media/motorsports/motorsports-34.jpg'
import motorsports35 from '../media/motorsports/motorsports-35.jpg'
import motorsports36 from '../media/motorsports/motorsports-36.jpg'
import motorsports37 from '../media/motorsports/motorsports-37.jpg'
import motorsports38 from '../media/motorsports/motorsports-38.jpg'
import motorsports39 from '../media/motorsports/motorsports-39.jpg'
import motorsports40 from '../media/motorsports/motorsports-40.jpg'
import motorsports41 from '../media/motorsports/motorsports-41.jpg'
import motorsports42 from '../media/motorsports/motorsports-42.jpg'
import motorsports43 from '../media/motorsports/motorsports-43.jpg'
import motorsports44 from '../media/motorsports/motorsports-44.jpg'
import motorsports45 from '../media/motorsports/motorsports-45.jpg'

import events1 from '../media/events/events-01.jpg'
import events2 from '../media/events/events-02.jpg'
import events3 from '../media/events/events-03.jpg'
import events4 from '../media/events/events-04.jpg'
import events5 from '../media/events/events-05.jpg'
import events6 from '../media/events/events-06.jpg'
import events7 from '../media/events/events-07.jpg'
import events8 from '../media/events/events-08.jpg'
import events9 from '../media/events/events-09.jpg'
import events10 from '../media/events/events-10.jpg'
import events11 from '../media/events/events-11.jpg'
import events12 from '../media/events/events-12.jpg'
import events13 from '../media/events/events-13.jpg'
import events14 from '../media/events/events-14.jpg'
import events15 from '../media/events/events-15.jpg'
import events16 from '../media/events/events-16.jpg'
import events17 from '../media/events/events-17.jpg'
import events18 from '../media/events/events-18.jpg'
import events19 from '../media/events/events-19.jpg'
import events20 from '../media/events/events-20.jpg'
import events21 from '../media/events/events-21.jpg'
import events22 from '../media/events/events-22.jpg'
import events23 from '../media/events/events-23.jpg'
import events24 from '../media/events/events-24.jpg'
import events25 from '../media/events/events-25.jpg'
import events26 from '../media/events/events-26.jpg'
import events27 from '../media/events/events-27.jpg'
import events28 from '../media/events/events-28.jpg'
import events29 from '../media/events/events-29.jpg'
import events30 from '../media/events/events-30.jpg'
import events31 from '../media/events/events-31.jpg'
import events32 from '../media/events/events-32.jpg'
import events33 from '../media/events/events-33.jpg'
import events34 from '../media/events/events-34.jpg'
import events35 from '../media/events/events-35.jpg'
import events36 from '../media/events/events-36.jpg'
import events37 from '../media/events/events-37.jpg'
import events38 from '../media/events/events-38.jpg'
import events39 from '../media/events/events-39.jpg'
import events40 from '../media/events/events-40.jpg'
import events41 from '../media/events/events-41.jpg'
import events42 from '../media/events/events-42.jpg'
import events43 from '../media/events/events-43.jpg'
import events44 from '../media/events/events-44.jpg'
import events45 from '../media/events/events-45.jpg'

export const homePhotoData: Photo[] = [
    new Photo(
        'Seceda, South Tyrol, Italy',
        landscape4,
    ),
    new Photo(
        "Philosopher's Walk, Toronto, Canada",
        urban5,
    ),
    new Photo(
        'Trinity College, Toronto, Canada',
        urban6,
    ),
    new Photo(
        "St Michael's College, Toronto, Canada",
        urban3,
    ),
    new Photo(
        'Emmanuel College, Toronto, Canada',
        urban4,
    ),
    new Photo(
        'Downtown, Toronto, Canada',
        urban12,
    ),
    new Photo(
        'Toronto Island, Toronto, Canada',
        urban15,
    ),
    new Photo(
        'Gooderham Building, Toronto, Canada',
        urban2,
    ),
    new Photo(
        'Nový Svět, Prague, Czech Republic',
        urban9,
    ),
    new Photo(
        'Varsity Stadium, Toronto, Canada',
        urban1,
    ),
    new Photo(
        'Zermatt, Switzerland',
        landscape1,
    ),
    new Photo(
        'CN Tower from University of Toronto, Toronto, Canada',
        urban21,
    ),
    new Photo(
        'Palacio de Bellas Artes, Mexico City, Mexico',
        urban18,
    ),
    new Photo(
        'Vyhlídka Máj, Czech Republic',
        landscape3,
    ),
    new Photo(
        'San Giovanni, South Tyrol, Italy',
        landscape9,
    ),
    new Photo(
        'Santa Madalena, South Tyrol, Italy',
        landscape2,
    )
]

const urbanPhotoData: Photo[] = [
    new Photo(
        'Varsity Stadium, Toronto, Canada',
        urban1,
    ),
    new Photo(
        'Gooderham Building, Toronto, Canada',
        urban2
    ),
    new Photo(
        "St Michael's College, Toronto, Canada",
        urban3
    ),
    new Photo(
        'Trinity College, Toronto, Canada',
        urban4
    ),
    new Photo(
        "Philosopher's Walk, Toronto, Canada",
        urban5
    ),
    new Photo(
        'Emmanuel College, Toronto, Canada',
        urban6
    ),
    new Photo(
        'Sv. Mikuláše, Prague, Czech Republic',
        urban7
    ),
    new Photo(
        'Staroměstské Náměstí, Prague, Czech Republic',
        urban8
    ),
    new Photo(
        'Nový Svět, Prague, Czech Republic',
        urban9
    ),
    new Photo(
        'Pražský Hrad, Prague, Czech Republic',
        urban10
    ),
    new Photo(
        'Financial District from the CN Tower, Toronto, Canada',
        urban11
    ),
    new Photo(
        'Downtown, Toronto, Canada',
        urban12
    ),
    new Photo(
        'Faculty of Music, University of Toronto, Toronto, Canada',
        urban13
    ),
    new Photo(
        'Victoria College, Toronto, Canada',
        urban14
    ),
    new Photo(
        'CN Tower from Toronto Islands, Toronto, Canada',
        urban15
    ),
    new Photo(
        'Mexico City, Mexico',
        urban16
    ),
    new Photo(
        'Palacio de Bellas Artes, Mexico City, Mexico',
        urban17
    ),
    new Photo(
        'Palacio de Bellas Artes, Mexico City, Mexico',
        urban18
    ),
    new Photo(
        'Soldiers Tower, University of Toronto, Toronto, Canada',
        urban19
    ),
    new Photo(
        'Front Campus, University of Toronto, Toronto, Canada',
        urban20
    ),
    new Photo(
        'CN Tower from University of Toronto, Toronto, Canada',
        urban21
    ),
    new Photo(
        'College Park from University of Toronto, Toronto, Canada',
        urban22
    ),
]

const landscapePhotoData: Photo[] = [
    new Photo(
        'Zermatt, Switzerland',
        landscape1,
    ),
    new Photo(
        'Santa Maddalena, South Tyrol, Italy',
        landscape2,
    ),
    new Photo(
        'Vyhlídka Máj, Czech Republic',
        landscape3,
    ),
    new Photo(
        'Seceda, South Tyrol, Italy',
        landscape4,
    ),
    new Photo(
        'Lake Louise, Banff, Alberta, Canada',
        landscape5,
    ),
    new Photo(
        'Tre Cime de Lavaredo, Dolomites, Italy',
        landscape6,
    ),
    new Photo(
        'Matterhorn, Switzerland',
        landscape7,
    ),
    new Photo(
        'Seceda, South Tyrol, Italy',
        landscape8,
    ),
    new Photo(
        'San Giovanni, South Tyrol, Italy',
        landscape9,
    ),
    new Photo(
        'Alpe de Siusi',
        landscape10,
    ),
]

const productPhotoData: Photo[] = [
    new Photo(
        'Omega / Artem Straps',
        product1
    ),
    new Photo(
        'F.P. Journe / Chrono 10:10',
        product2
    ),
    new Photo(
        'Omega / Artem Straps',
        product3
    ),
    new Photo(
        'Breitling',
        product4
    ),
    new Photo(
        'Christopher Ward / Artem Straps',
        product5
    ),
    new Photo(
        'Seiko / DLW Mods / Artem Straps',
        product6
    ),
    new Photo(
        'Ming Watches',
        product7
    ),
    new Photo(
        'Audemars Piguet / Chrono 10:10',
        product8
    ),
    new Photo(
        'Junghans / Hirsch Straps',
        product9
        ),
    new Photo(
        'F.P. Journe / Chrono 10:10',
        product10
    ),
]

const portraitPhotoData: Photo[] = [
    new Photo(
        'Polson Pier, Toronto',
        people1
    ),
    new Photo(
        'Polson Pier, Toronto',
        people2
    ),
    new Photo(
        'University of Toronto',
        people3
    ),
    new Photo(
        'University of Toronto',
        people4
    ),
    new Photo(
        'Downtown Toronto',
        people5
    ),
    new Photo(
        'Bloorcourt, Toronto',
        people6
    ),
    new Photo(
        'Bloorcourt, Toronto',
        people7
    ),
    new Photo(
        'Bloorcourt, Toronto',
        people8
    ),
    new Photo(
        'Bloorcourt, Toronto',
        people9
    ),
    new Photo(
        'Bloorcourt, Toronto',
        people10
    ),
    new Photo(
        'Bloorcourt, Toronto',
        people11
    ),
    new Photo(
        'Xochimilco, Mexico City, Mexico',
        people12
    ),
]

const sportsPhotoData: Photo[] = [
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // OUA Quarterfinals // February 2024',
        sports1,
    ),
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // OUA Quarterfinals // February 2024',
        sports2,
    ),
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // OUA Quarterfinals // February 2024',
        sports3,
    ),
    new Photo(
        'Varsity Blues vs Queen\'s Gaels // January 2024',
        sports4,
    ),
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // OUA Quarterfinals // February 2024',
        sports5,
    ),
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // OUA Quarterfinals // February 2024',
        sports6,
    ),
    new Photo(
        'Varsity Blues vs Nipissing Lakers // February 2024',
        sports7,
    ),
    new Photo(
        'Varsity Blues vs York Lions // January 2024',
        sports8,
    ),
    new Photo(
        'Varsity Blues vs York Lions // January 2024',
        sports9,
    ),
    new Photo(
        'Varsity Blues vs Western Mustangs // November 2023',
        sports10,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports11,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports12,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // December 2023',
        sports13,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // December 2023',
        sports14,
    ),
    new Photo(
        'Varsity Blues vs McMaster Marauders // September 2023',
        sports15,
    ),
    new Photo(
        'Varsity Blues vs McMaster Marauders // September 2023',
        sports16,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports17,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports18,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports19,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports20,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports21,
    ),
    new Photo(
        'Varsity Blues vs Waterloo Warriors // October 2023',
        sports22,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports23,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports24,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports25,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports26,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports27,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports28,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports29,
    ),
    new Photo(
        'Varsity Blues vs TMU Bold // October 2023',
        sports30,
    ),
    new Photo(
        'Varsity Blues vs Moncton Aigles Bleus // October 2023',
        sports31,
    ),
    new Photo(
        'Varsity Blues vs Western Mustangs // November 2023',
        sports32,
    ),
    new Photo(
        'Varsity Blues vs McGill Redmen // October 2023',
        sports33,
    ),
    new Photo(
        'Varsity Blues vs McGill Redmen // October 2023',
        sports34,
    ),
    new Photo(
        'Varsity Blues vs McGill Redmen // October 2023',
        sports35,
    ),
    new Photo(
        'Varsity Blues vs Laval Rouge et Or // October 2023',
        sports36,
    ),
    new Photo(
        'Varsity Blues vs Laval Rouge et Or // October 2023',
        sports37,
    ),
    new Photo(
        'Varsity Blues vs Laval Rouge et Or // October 2023',
        sports38,
    ),
    new Photo(
        'Varsity Blues vs McMaster Marauders // September 2023',
        sports39,
    ),
    new Photo(
        'Varsity Blues vs McMaster Marauders // September 2023',
        sports40,
    ),
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // January 2024',
        sports41,
    ),
    new Photo(
        'Varsity Blues vs Ontario Tech Ridgebacks // January 2024',
        sports42,
    ),
    new Photo(
        'Varsity Blues vs McMaster Marauders // September 2023',
        sports43,
    ),
]

const motorsportsPhotoData: Photo[] = [
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports1,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports2,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports3,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports4,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports5,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports6,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports7,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports8,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports9,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports10,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports11,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports12,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports13,
    ),
    new Photo(
        'F1 Canadian Grand Prix // June 2024',
        motorsports14,
    ),
    new Photo(
        'Honda Indy Toronto // July 2024',
        motorsports37,
    ),
    new Photo(
        'SCCC // Honda Indy Toronto // July 2024',
        motorsports38,
    ),
    new Photo(
        'Honda Indy Toronto // July 2024',
        motorsports39,
    ),
    new Photo(
        'Radical Cup // Honda Indy Toronto // July 2024',
        motorsports40,
    ),
    new Photo(
        'Honda Indy Toronto // July 2024',
        motorsports41,
    ),
    new Photo(
        'Honda Indy Toronto // July 2024',
        motorsports42,
    ),
    new Photo(
        'Honda Indy Toronto // July 2024',
        motorsports43,
    ),
    new Photo(
        'Colton Herta // Honda Indy Toronto // July 2024',
        motorsports44,
    ),
    new Photo(
        'Colton Herta // Honda Indy Toronto // July 2024',
        motorsports45,
    ),
    new Photo(
        'Ducati Owners Club of Canada // CTMP // May 2024',
        motorsports15,
    ),
    new Photo(
        'Ducati Owners Club of Canada // CTMP // May 2024',
        motorsports16,
    ),
    new Photo(
        'Ducati Owners Club of Canada // CTMP // May 2024',
        motorsports17,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports18,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports19,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports20,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports21,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports22,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports23,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports24,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports25,
    ),
    new Photo(
        'CSCS // CTMP // May 2024',
        motorsports26,
    ),
    new Photo(
        'CSCS Drift Exhibition // CTMP // May 2024',
        motorsports27,
    ),
    new Photo(
        'NASCAR Canada Castrol Victoria Day SpeedFest // CTMP // May 2024',
        motorsports28,
    ),
    new Photo(
        'NASCAR Canada Castrol Victoria Day SpeedFest // CTMP // May 2024',
        motorsports29,
    ),
    new Photo(
        'NASCAR Canada Castrol Victoria Day SpeedFest // CTMP // May 2024',
        motorsports30,
    ),
    new Photo(
        'NASCAR Canada Castrol Victoria Day SpeedFest // CTMP // May 2024',
        motorsports31,
    ),
    new Photo(
        'Sports Car Championship of Canada // CTMP // May 2024',
        motorsports32,
    ),
    new Photo(
        'NASCAR Canada Castrol Victoria Day SpeedFest // CTMP // May 2024',
        motorsports33,
    ),
    new Photo(
        'Nissan Sentra Cup // CTMP // May 2024',
        motorsports34,
    ),
    new Photo(
        'Sports Car Championship of Canada // CTMP // May 2024',
        motorsports35,
    ),
    new Photo(
        'Nissan Sentra Cup // CTMP // May 2024',
        motorsports36,
    ),
]

const eventsPhotoData: Photo[] = [
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events1,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events2,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events3,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events4,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events5,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events6,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events7,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events8,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events9,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events10,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events11,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events12,
    ),
    new Photo(
        '142nd Trinity College Conversat // March 2025',
        events13,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events14,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events15,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events16,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events17,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events18,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events19,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events20,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events21,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events22,
    ),
    new Photo(
        'Trinity College Saints Ball // November 2023',
        events23,
    ),
    new Photo(
        'Trinity College Saints Ball // November 2023',
        events24,
    ),
    new Photo(
        'Trinity College Saints Ball // November 2023',
        events25,
    ),
    new Photo(
        'Trinity College Saints Ball // November 2023',
        events26,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events27,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events28,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events29,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2025',
        events30,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2024',
        events31,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2024',
        events32,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2024',
        events33,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2024',
        events34,
    ),
    new Photo(
        'St. Michael\'s College Student Union Formal // March 2024',
        events35,
    ),
    new Photo(
        'St. Michael\' College Graduation BBQ // June 2024',
        events36,
    ),
    new Photo(
        'St. Michael\' College Graduation BBQ // June 2024',
        events37,
    ),
    new Photo(
        'St. Michael\' College Graduation BBQ // June 2024',
        events38,
    ),
    new Photo(
        'St. Michael\' College Graduation BBQ // June 2024',
        events39,
    ),
    new Photo(
        'St. Michael\' College Graduation BBQ // June 2024',
        events40,
    ),
    new Photo(
        'URSA Career Speaker Panel // September 2024',
        events41,
    ),
    new Photo(
        'URSA Career Speaker Panel // September 2024',
        events42,
    ),
    new Photo(
        'URSA Synergy Conference // Feburary 2025',
        events43,
    ),
    new Photo(
        'URSA Synergy Conference // Feburary 2025',
        events44,
    ),
    new Photo(
        'URSA Synergy Conference // Feburary 2025',
        events45,
    ),
]

export const portfolioPhotoData = {
    urban: urbanPhotoData,
    landscape: landscapePhotoData,
    product: productPhotoData,
    portrait: portraitPhotoData,
    sports: sportsPhotoData,
    motorsports: motorsportsPhotoData,
    events: eventsPhotoData,
}