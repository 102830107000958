import * as React from 'react'
import { useEffect } from 'react'
import { ChakraProvider, LightMode, Grid } from '@chakra-ui/react'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import Home from './pages/Home'
import Nav from './components/Nav'
import Fonts from './fonts'
import theme from './theme'
import '@fontsource-variable/lexend-deca'
import { useLocalStorage } from 'usehooks-ts'

import About from './pages/About'
import Contact from './pages/Contact'
import Travel from './pages/Travel'
import Shop from './pages/Shop'
import NotFound from './pages/NotFound'
import PortfolioPage, { PortfolioPhotoPage } from './pages/Portfolio'

export const App = () => {
    const { pathname } = useLocation()
    const [, setChakraColorMode] = useLocalStorage(
        'chakra-ui-color-mode',
        'light',
    )

    useEffect(() => {
        document.title = 'Jason Wang'
        setChakraColorMode('light')
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <ChakraProvider theme={theme}>
            <Fonts />
            <LightMode>
                <Grid
                    templateAreas={[`"nav" "main"`, `"nav main"`]}
                    gridTemplateColumns={{ md: '16rem 1fr' }}
                    gridTemplateRows={{ base: '4rem 1fr', md: '1fr' }}
                    h="100vh"
                >
                    <Nav />
                    <Routes>
                        <Route index path="/" element={<Home />} />
                        <Route path="/portfolio/:portfolioId" element={<PortfolioPage />} />
                        <Route path="/portfolio/:portfolioId/:photoId" element={<PortfolioPhotoPage />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/travel" element={<Travel />} />
                        <Route path="/shop" element={<Shop />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<Navigate to="/404" />} />
                    </Routes>
                </Grid>
            </LightMode>
        </ChakraProvider>
    )
}
