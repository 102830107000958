import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { getPortfolioPhotos } from '../../helpers/getPhotoData';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { Photo } from '../../models/Photo';
import PhotoGrid from './PhotoGrid';

const PortfolioGallery = () => {
    const { portfolioId } = useParams()
    const navigate = useNavigate()

    const photoData = getPortfolioPhotos(portfolioId ?? '')

    return (
        <Wrap spacing={6} justify={'center'} pb={10} pr={[0, 4]}>
            {photoData.map((photo: Photo, i) => (
                <WrapItem key={i}>
                    <PhotoGrid photo={photo} onClick={() => navigate(`/portfolio/${portfolioId}/${photo.id}`)} />
                </WrapItem>
            ))}
        </Wrap>
    );
};

export default PortfolioGallery;