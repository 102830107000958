import { Skeleton, AspectRatio, Image, Text, Box, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Photo } from '../../models/Photo'
import WavyRightArrow from './Arrow'

interface ImageBannerLinkProps {
    title: string
    photo: Photo
    onClick?: () => void
}

const ImageBannerLink = ({ title, photo, onClick }: ImageBannerLinkProps) => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)

    return (
        <Skeleton
            isLoaded={hasLoaded}
            boxSize="100%"
            fadeDuration={1}
            startColor="gray.300"
            endColor="gray.200"
        >
            <Box
                position={'relative'}
                onClick={onClick}
                transition={'0.3s'}
                _hover={onClick && {
                    cursor: 'pointer',
                    opacity: 0.925,
                }}
            >
                <AspectRatio
                    ratio={16 / 9}
                    w={'full'}
                >
                    <Image
                        src={photo.src}
                        aria-label={photo.title}
                        objectFit={'cover'}
                        onLoad={() => setHasLoaded(true)}
                        display={hasLoaded ? 'block' : 'none'}
                    />
                </AspectRatio>
                <HStack
                    gap={2}
                    position={'absolute'}
                    bottom={[2, 4]}
                    right={[0, 4]}
                    color={'white'}
                    fontSize={['1.25rem', '2rem']}
                    letterSpacing={'0.01rem'}
                    fontFamily={'Against'}
                >
                    <Text>
                        {title.toLowerCase()}
                    </Text>
                    <WavyRightArrow width={100} height={30} />
                </HStack>
            </Box>
        </Skeleton>
    )
}

export default ImageBannerLink