import React, { useCallback, useEffect, useState } from 'react'
import { Photo } from '../../models/Photo'
import { Image, Text, Center, Flex, VStack, Link } from '@chakra-ui/react'

interface PhotoViewerProps {
    photo: Photo
    setCurrentPhoto: () => void
    setPrevPhoto: () => void
    setNextPhoto: () => void
}

const PhotoViewer = ({
    photo,
    setCurrentPhoto,
    setPrevPhoto,
    setNextPhoto,
}: PhotoViewerProps) => {
    const [isFading, setIsFading] = useState<boolean>(true)

    const handleSetPrevPhoto = useCallback(() => {
        setIsFading(true)
        setTimeout(() => {
            setPrevPhoto()
            setIsFading(false)
        }, 200)
    }, [setPrevPhoto])

    const handleSetNextPhoto = useCallback(() => {
        setIsFading(true)
        setTimeout(() => {
            setNextPhoto()
            setIsFading(false)
        }, 200)
    }, [setNextPhoto])

    const handleSetCurrentPhoto = useCallback(() => {
        setIsFading(true)
        setTimeout(() => {
            setCurrentPhoto()
            setIsFading(false)
        }, 200)
    },
        [setCurrentPhoto],
    )

    useEffect(() => {
        // Event listeners for left and right arrow keys to navigate between photos
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                handleSetPrevPhoto()
            } else if (e.key === 'ArrowRight') {
                handleSetNextPhoto()
            } else if (e.key === 'Escape') {
                handleSetCurrentPhoto()
            }
        }
        window.addEventListener('keydown', handleKeyDown)

        // Reset the fading state and event listeners when the component is unmounted
        return () => {
            setIsFading(false)
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleSetCurrentPhoto, handleSetNextPhoto, handleSetPrevPhoto])

    return (
        <Flex py={[0, 5]} w={'100%'} h={'100%'} direction={['column', 'row']}>
            <Center
                px={10}
                h={'100%'}
                flex={'1'}
                opacity={isFading ? 0.3 : 1}
                transition={'0.3s'}
                onLoad={() => setIsFading(false)}
            >
                <Image
                    src={photo.src}
                    aria-label={photo.title}
                    objectFit={'contain'}
                    h={'100%'}
                />
            </Center>

            <VStack
                pr={[10, 20]}
                pb={10}
                w={['100%', '16rem']}
                h={['16rem', '100%']}
                textAlign={'right'}
                spacing={[2, 4]}
            >
                <Text
                    fontWeight={600}
                    w={'100%'}
                    opacity={isFading ? 0.3 : 1}
                    transition={'0.3s'}
                >
                    {photo.title}
                </Text>
                <Text
                    color={'gray.600'}
                    w={'100%'}
                    fontWeight={300}
                    textTransform={'uppercase'}
                    fontSize={'0.85rem'}
                >
                    <Link
                        color={'black'}
                        _hover={{ textDecor: 'none', color: 'gray.600' }}
                        onClick={() => handleSetPrevPhoto()}
                    >
                        Prev
                    </Link>
                    &nbsp; / &nbsp;
                    <Link
                        color={'black'}
                        _hover={{ textDecor: 'none', color: 'gray.600' }}
                        onClick={() => handleSetNextPhoto()}
                    >
                        Next
                    </Link>
                </Text>
                <Text
                    color={'gray.600'}
                    w={'100%'}
                    fontWeight={300}
                    textTransform={'uppercase'}
                    fontSize={'0.85rem'}
                >
                    <Link
                        color={'black'}
                        _hover={{ textDecor: 'none', color: 'gray.600' }}
                        onClick={() => handleSetCurrentPhoto()}
                    >
                        Grid view
                    </Link>
                </Text>
            </VStack>
        </Flex>
    )
}

export default PhotoViewer
