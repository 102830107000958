import React from 'react';
import PortfolioPhotoViewer from '../../components/PortfolioPhotoViewer';
import { Page } from '../../components/Page';

export const PortfolioPhotoPage = () => {
    return (
        <Page>
            <PortfolioPhotoViewer />
        </Page>
    );
};