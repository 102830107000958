import { Skeleton, AspectRatio, Image } from "@chakra-ui/react"
import { useState } from "react"
import { Photo } from "../../models/Photo"

interface PhotoGridProps {
    photo: Photo
    onClick?: () => void
}

const PhotoGrid = ({
    photo,
    onClick
}: PhotoGridProps) => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)

    return (
        <Skeleton
            isLoaded={hasLoaded}
            boxSize="100%"
            fadeDuration={1}
            startColor="gray.300"
            endColor="gray.200"
        >
            <AspectRatio key={photo.id} ratio={1 / 1} w={'20rem'}>
                <Image
                    src={photo.src}
                    aria-label={photo.title}
                    objectFit={'cover'}
                    onLoad={() => setHasLoaded(true)}
                    display={hasLoaded ? 'block' : 'none'}
                    onClick={onClick}
                    transition={'0.3s'}
                    _hover={onClick && {
                        cursor: 'pointer',
                        opacity: 0.925,
                    }}
                />
            </AspectRatio>
        </Skeleton>
    )
}

export default PhotoGrid