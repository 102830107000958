import { GridItem, Box, Stack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

export const Page = ({ children }: PropsWithChildren) => (
    <GridItem area={'main'}>
        <Box py={[5, 10]} textAlign={'center'} w={'100%'} h={'100vh'}>
            {children}
        </Box>
    </GridItem>
);

export const PageWithImage = ({ children }: PropsWithChildren) => (
    <GridItem area={'main'}>
        <Stack direction={['column', 'row']} h={'100vh'} py={[4, 16]}>
            {children}
        </Stack>
    </GridItem>
)
