import CryptoJS from 'crypto-js'

export class Photo {
    id: string
    title: string
    src: string 

    constructor(title: string, src: string) {
        this.id = CryptoJS.MD5(title + src).toString();
        this.title = title
        this.src = src
    }
}
