import { Photo } from "../models/Photo"
import eventsPhoto from '../media/homePage/events.jpg'
import sportsPhoto from '../media/homePage/sports.jpg'
import motorsportsPhoto from '../media/homePage/motorsports.jpg'
import urbanPhoto from '../media/homePage/urban.jpg'
import landscapePhoto from '../media/homePage/landscape.jpg'
import productPhoto from '../media/homePage/product.jpg'
import portraitPhoto from '../media/homePage/portrait.jpg'

export const homePageData: Record<string, [string, Photo]> = {
    Events: ['portfolio/events', new Photo('', eventsPhoto)],
    Sports: ['portfolio/sports', new Photo('', sportsPhoto)],
    Motorsports: ['portfolio/motorsports', new Photo('', motorsportsPhoto)],
    Urban: ['portfolio/urban', new Photo('', urbanPhoto)],
    Landscape: ['portfolio/landscape', new Photo('', landscapePhoto)],
    Product: ['portfolio/product', new Photo('', productPhoto)],
    People: ['portfolio/portrait', new Photo('', portraitPhoto)],
}