import React, { useState } from 'react'
import {
    Heading,
    Image,
    VStack,
    Text,
    Center,
    Link,
    Skeleton,
} from '@chakra-ui/react'

import selfPortraitPhoto from '../../media/jasonwang.jpg'
import { PageWithImage } from '../../components/Page'

const InlineLink = ({
    children,
    ...props
}: {
    children: React.ReactNode
    [x: string]: any
}) => (
    <Link
        px={'1px'}
        fontWeight={500}
        transition={'0.2s ease'}
        color={'gray.500'}
        borderBottom={'2px solid'}
        borderBottomColor={'gray.500'}
        _hover={{
            color: 'gray.400',
            borderBottomColor: 'gray.400',
        }}
        {...props}
    >
        {children}
    </Link>
)

const About = () => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)

    return (
        <PageWithImage>
            <Center w={['100%', '50%']} px={6}>
                <Skeleton
                    isLoaded={hasLoaded}
                    h={['20rem', '100%']}
                    fadeDuration={1}
                    startColor="gray.300"
                    endColor="gray.200"
                >
                    <Image
                        src={selfPortraitPhoto}
                        h={['20rem', '100%']}
                        objectFit={'cover'}
                        onLoad={() => setHasLoaded(true)}
                    />
                </Skeleton>
            </Center>
            <VStack
                spacing={6}
                textAlign={['center', 'justify']}
                px={6}
                pt={[4, 0]}
                pb={[16, 0]}
                w={['100%', '36rem']}
                lineHeight={1.75}
            >
                <Heading fontSize={'1.5rem'}>ABOUT</Heading>
                <Text>
                    Hi, I'm Jason and I'm a photographer based out of
                    Toronto, Canada specialising in urban, landscape, and
                    sports photography. I enjoy approaching photography by
                    finding unique perspectives that capture the beauty of
                    urban life and nature one still at a time. I am
                    currently a sports photographer for the University of
                    Toronto Varsity Blues.
                </Text>
                <Text>
                    I am also an undergraduate student at the University of
                    Toronto studying Computer Science and Cognitive Science.
                    Feel free to check out my{' '}
                    <InlineLink href={'https://jasonfyw.com'}>
                        main personal website
                    </InlineLink>{' '}
                    to learn more about me and my work in software
                    development.
                </Text>
                <Heading fontSize={'1rem'} pt={4}>
                    Gear
                </Heading>
                <VStack spacing={0}>
                    <Text>Sony a7Rv</Text>
                    <Text>Sony a7iii</Text>
                    <Text>Sony 70-200mm f2.8 GM II</Text>
                    <Text>Sigma 24-70mm f2.8</Text>
                    <Text>Sony 20mm f1.8 G</Text>
                    <Text>Sigma 85mm f1.4</Text>
                </VStack>
            </VStack>
        </PageWithImage>
    )
}

export default About
