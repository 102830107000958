import React from 'react'
import { VStack } from '@chakra-ui/react'
import { Page } from '../../components/Page'
import ImageBannerLink from '../../components/ImageBannerLink'
import { useNavigate } from 'react-router'
import { homePageData } from '../../data/homePageData'

const Home = () => {
    const navigate = useNavigate()

    return (
        <Page>
            <VStack spacing={6} pb={10} px={[4, 8]}>
                {
                    Object.entries(homePageData).map(([title, value], i) => (
                        <ImageBannerLink
                            key={i}
                            title={title}
                            photo={value[1]}
                            onClick={() => navigate(value[0])}
                        />
                    ))
                }
            </VStack>
        </Page>
    )
}

export default Home
